import React from "react";
import Markdown from "react-markdown";
import { jsx } from "theme-ui";
import { Link } from "./core";
/** @jsx jsx */

const ReactMarkdown = ({
  children,
  components,
  withParagraphe = true,
  ...props
}) => {
  return (
    <Markdown
      components={{
        a: ({ node, href, ...props }) => {
          const isExternal = !href.includes("https://www.cleanmycalanques.fr/");
          return (
            <Link
              to={href}
              variant="content"
              external={isExternal}
              {...props}
            />
          );
        },
        p: ({ node, ...props }) =>
          withParagraphe ? <p {...props} /> : <React.Fragment {...props} />,

        ...components,
      }}
      {...props}
    >
      {children}
    </Markdown>
  );
};

export { ReactMarkdown };
