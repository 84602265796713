import React from "react";
import { jsx, Box } from "theme-ui";
import { AnimatePresence, motion } from "framer-motion";
import { Heading } from "../Heading";
import { Flex } from "../Flex";
import { Chevron } from "./Chevron";
import { Text } from "../Text";
import { ReactMarkdown } from "../../ReactMarkdown";

/** @jsx jsx */

const Accordion = React.forwardRef(
  ({ variant = "default", title, isOpen, onClick, children }, forwardedRef) => {
    return (
      <Box
        sx={{
          width: "100%",
          borderBottom: "2px solid #EAF1F3",
          overflow: "hidden",
        }}
      >
        <Flex
          className="noTapHighlight"
          positions={["space-between", "center"]}
          onClick={() => {
            onClick && onClick();
          }}
          sx={{
            cursor: "pointer",
            paddingBottom: "20px",
          }}
        >
          <Heading as="h3" fontSize="20px!important" color="secondary" sx={{ maxWidth: "85%" }}>
            {title}
          </Heading>
          <Chevron isOpen={isOpen} />
        </Flex>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              sx={{
                boxSizing: "border-box",
              }}
              initial={{
                height: 0,
                opacity: 0,
              }}
              animate={{
                height: "100%",
                opacity: 1,
              }}
              exit={{
                height: 0,
                opacity: 0,
              }}
              transition={{
                opacity: {
                  duration: 0.1,
                },
              }}
            >
              <Box sx={{ paddingBottom: "20px", paddingRight: "40px" }}>
                <Text fontSize={"14px"}>
                  <ReactMarkdown>
                    {children}
                  </ReactMarkdown>
                </Text>
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    );
  }
);

export { Accordion };
