import React from "react";

const RowContext = React.createContext(undefined);

function useRow() {
  const context = React.useContext(RowContext);
  if (context === undefined) {
    throw new Error("Col must be used within a Row");
  }
  return context;
}

export { RowContext, useRow };
