import React, { useEffect } from "react";
import { jsx, Button, useThemeUI } from "theme-ui";
import { motion, useAnimation } from "framer-motion";
import { darken } from "@theme-ui/color";
import useThemeColor from "../../hooks/useThemeColor";
import { navigate } from "../../helpers/navigate";

/** @jsx jsx */

const MotionButton = motion.custom(Button);

const CoreButton = React.forwardRef(
  (
    {
      variant = "primary",
      backgroundColor = "primary",
      outline = false,
      initial,
      to = null,
      onClick,
      disabled = false,
      download,
      external = false,
      active = false,
      ...props
    },
    forwardedRef
  ) => {
    const { theme } = useThemeUI();
    const localBackgroundColor = useThemeColor(backgroundColor);

    const controls = useAnimation();

    useEffect(() => {
      if(active){
        controls.start('active');
      } else {
        controls.start('initial');
      }
    }, [active])

    const handleOnClick = () => {
      if (!download) {
        if (to) {
          navigate(to, { external });
        }
        if (onClick) {
          onClick();
        }
      }
    };
    const buttonVariants = {
      initial: {
        backgroundColor: outline ? "#FFF" : localBackgroundColor,
        border: outline ? "2px solid" : "initial",
        borderColor: outline ? localBackgroundColor : "initial",
        color: outline ? localBackgroundColor: "#FFF",
        ...initial,
      },
      hover: {
        backgroundColor: outline
          ? localBackgroundColor
          : darken(localBackgroundColor, 0.05)(theme),
        borderColor: outline ? localBackgroundColor : "initial",
        color: "#FFF",
      },
      tap: {
        backgroundColor: outline
          ? darken(localBackgroundColor, 0.05)(theme)
          : darken(localBackgroundColor, 0.1)(theme),
        borderColor: outline
          ? darken(localBackgroundColor, 0.05)(theme)
          : "initial",
      },
      active: {
        backgroundColor: outline
          ? localBackgroundColor
          : darken(localBackgroundColor, 0.05)(theme),
        borderColor: outline ? localBackgroundColor : "initial",
        color: "#FFF",
      }
    };

    return (
      <MotionButton
        ref={forwardedRef}
        variant={variant}
        href={to}
        animate={controls}
        initial={buttonVariants['initial']}
        whileHover={!disabled ? "hover" : "initial"}
        whileTap={!disabled ? "tap" : "initial"}
        variants={buttonVariants}
        transition={{ duration: 0.2 }}
        onClick={handleOnClick}
        disabled={disabled}
        download={download}
        {...props}
      />
    );
  }
);

export { CoreButton as Button };
