import React from "react";
import { Flex, useThemeUI } from "theme-ui";
import { motion } from "framer-motion";
import { useResponsiveValue } from "../../hooks/breakpoints";

const defaultPosition = ["center", "center"];

const CoreFlex = React.forwardRef(
  (
    { direction = "row", positions = defaultPosition, gap, wrap, sx, ...props },
    forwardedRef
  ) => {
    const { theme } = useThemeUI();
    const localPosition = useResponsiveValue(
      Array.isArray(positions[0]) ? positions : [positions]
    );
    const localDirection = useResponsiveValue(
      Array.isArray(direction) ? direction : [direction]
    );
    const responsiveGap = useResponsiveValue(Array.isArray(gap) ? gap : [gap]);
    const localGap =
      typeof responsiveGap === "number"
        ? `${theme.space[responsiveGap]}px`
        : responsiveGap;

    return (
      <Flex
        ref={forwardedRef}
        sx={{
          flexDirection: localDirection,
          justifyContent:
            localDirection === "column" ? localPosition[1] : localPosition[0],
          alignItems:
            localDirection === "column" ? localPosition[0] : localPosition[1],
          // gap,
          gap: localGap,
          flexWrap: wrap,
/*          "& > * + *": {
            marginLeft:
              localDirection === "row" && localGap
                ? `${localGap} !important`
                : null,
            marginTop:
              localDirection === "column" && localGap
                ? `${localGap} !important`
                : null,
          },*/
          ...sx,
        }}
        {...props}
      />
    );
  }
);

const MotionFlex = motion.custom(CoreFlex);
export { CoreFlex as Flex, MotionFlex };
