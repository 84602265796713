import React from "react";
import { RowContext } from "./useRow";
import { Box } from "theme-ui";
import { useResponsiveValue } from "../../../hooks/breakpoints";

function Row({ className, children, gap, align }) {
  const responsiveGap = useResponsiveValue(Array.isArray(gap) ? gap : [gap]);
  return (
    <Box
      className={className}
      sx={{
        display: "flex",
        flexFlow: "row wrap",
        rowGap: responsiveGap,
        marginLeft: responsiveGap && `-${parseInt(responsiveGap) / 2}px`,
        marginRight: responsiveGap && `-${parseInt(responsiveGap) / 2}px`,
        justifyContent: align,
      }}
    >
      <RowContext.Provider value={{ gap: responsiveGap }}>
        {children}
      </RowContext.Provider>
    </Box>
  );
}

export { Row };
