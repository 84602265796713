import React from "react";
import { useRow } from "./useRow";
import { useResponsiveValue } from "../../../hooks/breakpoints";
import { Box } from "theme-ui";

const getPourcentage = (column) => `${(100 * column) / 12}%`;
const getFlex = (column) => `0 0 ${getPourcentage(column)}`;

function Col({ className, children, order, column = 12 }) {
  const { gap } = useRow();

  const responsiveColumn = useResponsiveValue(
    Array.isArray(column) ? column : [column]
  );
  return (
    <Box
      className={className}
      sx={{
        flex: getFlex(responsiveColumn),
        maxWidth: getPourcentage(responsiveColumn),
        paddingRight: gap && `${parseInt(gap) / 2}px`,
        paddingLeft: gap && `${parseInt(gap) / 2}px`,
        order,
      }}
    >
      {children}
    </Box>
  );
}

export { Col };
